$primary-main: #00648a;
$primary-light: #66a2b9;

$secondary-main: #f26600;
$secondary-light: #f7a366;

$common-white: #ffffff;
$common-black: #3c3c3c;
$common-grey: #6f6b6a;
$common-lightgrey: #c6c6c6;
$common-lightergrey: #e8e8e8;
$common-whitegrey: #f4f4f4;
