@import "../../styles/_colors";

.dg-scroll {
    &::-webkit-scrollbar {
        background-color: $common-lightgrey;
        height: 6px;
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $common-grey;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-corner {
        background-color: $common-lightgrey;
    }
}

.dg-wrapper {
    display: flex;
    &.top {
        border: 1px solid $common-lightgrey;
    }

    &.bottom {
        border-bottom: 1px solid $common-lightgrey;
        border-left: 1px solid $common-lightgrey;
        border-right: 1px solid $common-lightgrey;
    }
}

.dg-grid {

    &:not(.body):not(.visible-overflow) {
        overflow: hidden !important;
    }

    &.visible-overflow {
        overflow: visible !important;
    }

    &.body {
        &[data-is-chrome="true"] {
            will-change: unset !important;
        }
    }

    &.header {
        &:hover {
            .dg-resizer {
                opacity: 1;
            }
        }
    }

    &.fixed {
        vertical-align: top;
    }
}

%cell-adornment {
    top: 25%;
    width: 1px;
    height: 50%;
    content: "";
    position: absolute;
    background: #e0e0e0;
    -moz-appearance: initial;
}

.dg-grid .dg-cell-w {
    &:not([data-fixed="true"]):not([data-col="0"]):before {
        @extend %cell-adornment;
        left: 1px;
    }

    &[data-fixed="true"]:after {
        @extend %cell-adornment;
        right: 2px;
    }
}

.dg-grid .dg-cell {
    &:not([data-no-pad="true"])>* {
        padding: 8px;
        padding-left: 16px;
    }

    &>*>*[data-no-left-pad="true"] {
        margin-left: -16px;
    }

    &[data-isOpen="true"] {
        border-bottom: 1px solid $common-lightgrey;
        background-color: #FFDDDD;
        &[data-hovered="true"] {
            background-color: #FFEEEE;
        }
    }
    
    &[data-isOpen="false"] {
        border-bottom: 1px solid $common-lightgrey;
        background-color: #DDFFDD;
        &[data-hovered="true"] {
            background-color: #EEFFEE;
        }
    }

    &[data-isOpen="warning"] {
        border-bottom: 1px solid $common-lightgrey;
        background: repeating-linear-gradient(
            45deg,
            #FFDDDD,
            #FFDDDD 10px,
            #FFCCCC 10px,
            #FFCCCC 20px
        );
        &[data-hovered="true"] {
            background: repeating-linear-gradient(
                45deg,
                #FFEEEE,
                #FFEEEE 10px,
                #FFDDDD 10px,
                #FFDDDD 20px
            );
        }
    }

    &[data-hovered="true"] {
        background: $common-whitegrey;
    }

    height: 100%;
    display: flex;
    font-size: 0.78125rem;
    box-sizing: border-box;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    overflow: visible;

    & > :first-child {
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        // user-select: none;
    }
}

.dg-grid .dg-hcell {
    color: #6f6b6a;
    height: 100%;
    display: flex;
    padding: 8px;
    font-size: 0.84375rem;
    box-sizing: border-box;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    padding-left: 16px;
    overflow: visible;

    & > :first-child {
        overflow: hidden;
        align-self: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 45px;
        user-select: auto;
    }

    &:hover {
        & .dg-sort:not([aria-hidden="false"]) {
            opacity: 0.5;
        }
    }

    &:not([data-fixed="true"]):not([data-col="0"]):before {
        @extend %cell-adornment;
        left: 1px;
    }

    &[data-fixed="true"]:after {
        @extend %cell-adornment;
        right: 2px;
    }

    & .dg-sort {
        vertical-align: middle;
        margin-left: 4px;
        margin-bottom: 1px;
    }
}

.dg-pagination {
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    height: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 16px;
}

.dg-grid .dg-resizer {
    position: absolute;
    cursor: col-resize !important;
    z-index: 1;
    opacity: 0;
    border-left: 1px solid $primary-light;
    border-right: 1px solid $primary-light;
    height: 50%;
    top: 25%;
    transition: all linear 100ms;
    right: -5px;
    width: 5px;
    &.handle-active {
        opacity: 1;
        border: none;
        background-color: $primary-light;
        height: calc(100% - 4px);
        top: 2px;
        right: -3px;
        width: 2px;
    }
}